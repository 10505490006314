import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@core/modules/translation';

@Pipe({
	standalone: true,
	name: 'filesize',
})
export class FileSizePipe implements PipeTransform {
	private readonly translateRoute = 'UTILS.SIZES.';

	constructor(private translator: TranslateService) {}

	transform(bytesInput?: number | string | null, decimals = 2): string {
		if (!bytesInput) {
			return '--';
		}
		const bytes =
			typeof bytesInput === 'string'
				? Number.parseInt(bytesInput, 10)
				: bytesInput;

		if (bytes === 0) {
			return '0\xa0' + this.translator.translate(this.translateRoute + 'B');
		}

		const k = 1000;
		const dm = Math.max(decimals, 0);
		const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

		const i = Math.floor(Math.log(bytes) / Math.log(k));

		return (
			parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) +
			' ' +
			this.translator.translate(this.translateRoute + sizes[i])
		);
	}
}
