import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpErrorResponse,
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

import {Observable, of, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {TokenStore} from '../services/stores/token.store';
import {UserStore} from '../services/stores/user.store';
import {environment} from '@env/environment';

/**
 * Provides Interceptor to clone request with JWT header
 */
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	constructor(
		private tokenStore: TokenStore,
		private router: Router,
		private userStore: UserStore
	) {}

	intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		if (this.tokenStore.checkToken() && req.url.includes('greenencoder')) {
			const token = this.tokenStore.getTokenRaw();
			req = this.addHeader(req, token);
		}
		return next.handle(req).pipe(catchError(x => this.handleAuthError(x)));
	}

	private handleAuthError(err: HttpErrorResponse): Observable<any> {
		if (err.status === 401 && err.url !== environment.root_url + '/login') {
			this.tokenStore.clearToken();
			this.userStore.clearUser();
			this.router.navigate(['/login']).then(() => window.location.reload());
			return of(err?.message); // or EMPTY may be appropriate here
		}
		return throwError(err);
	}

	private addHeader = (
		req: HttpRequest<any>,
		token: string | undefined
	): HttpRequest<any> =>
		req.clone(token ? {setHeaders: {Authorization: 'Bearer ' + token}} : {});
}
