import {Component, HostBinding, Input} from '@angular/core';

import {TranslatePipe} from '@shared/pipes';

@Component({
	standalone: true,
	selector: 'green-header',
	imports: [TranslatePipe],
	template: `
		<h1 class="is-primary text-lg md:text-2xl">{{ title | translate }}</h1>
		<p class="legend text-sm md:text-lg">{{ description | translate }}</p>
	`,
})
export class HeaderComponent {
	@Input({required: true}) title!: string;
	@Input({required: true}) description!: string;

	@HostBinding('class') class =
		'px-4 py-3 mb-2 md:flex items-end justify-start gap-3 w-full';
}
