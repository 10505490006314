import {NgClass, NgIf} from '@angular/common';
import {
	Component,
	EventEmitter,
	HostBinding,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TruncateTextPipe} from '@shared/pipes';

const MaterialModules = [MatTooltipModule, MatIconModule, MatButtonModule];

@Component({
	standalone: true,
	selector: 'green-editable-text',
	imports: [NgIf, NgClass, FormsModule, ...MaterialModules, TruncateTextPipe],
	templateUrl: './editable-text.component.html',
	styles: [
		`
			:host .non-editable {
				padding: 0.25em;
			}
		`,
		`
			:host .editable {
				border: 1px solid #00cfbb;
			}
		`,
	],
})
export class EditableTextComponent implements OnChanges {
	@Input() value = '';
	@Input() uploadDialog = false;
	@Input() length = 30;
	@Output() submitEvent = new EventEmitter();

	@HostBinding('class') class = 'inline-block';

	toggleInput = false;
	private initialValue: string;

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.value) {
			const newValue = changes.value.currentValue;
			this.initialValue = newValue;
			this.value = newValue;
		}
	}

	send(value: string): void {
		this.submitEvent.emit(value);
		this.toggleView();
	}

	toggleView(): void {
		this.toggleInput = !this.toggleInput;
	}

	reset(): void {
		this.value = this.initialValue;
		this.toggleView();
	}
}
