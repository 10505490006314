import {NgClass} from '@angular/common';
import {
	Component,
	EventEmitter,
	Input,
	Output,
	SimpleChanges,
} from '@angular/core';
import {DisplayMode} from '@shared/modules/library/types';

@Component({
	selector: 'green-switch-mode',
	imports: [NgClass],
	standalone: true,
	template: ` <div
		class="bg-gray-200 dark:bg-neutral-700 text-sm text-gray-500 dark:text-[#C447E1] border-2 border-gray-200 dark:border-neutral-700 rounded-full inline-flex">
		<button
			(click)="toggle.emit('GRID')"
			[ngClass]="
				mode === 'GRID'
					? 'bg-white rounded-full	text-[#2ccbba] dark:bg-neutral-800'
					: ''
			"
			class="inline-flex items-center transition-colors duration-300 ease-in focus:outline-none rounded-l-full px-4 py-2"
			id="grid">
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				stroke="currentColor"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
				class="fill-current w-4 h-4 mr-2">
				<rect x="3" y="3" width="7" height="7"></rect>
				<rect x="14" y="3" width="7" height="7"></rect>
				<rect x="14" y="14" width="7" height="7"></rect>
				<rect x="3" y="14" width="7" height="7"></rect>
			</svg>
			<span>Grid</span>
		</button>
		<button
			(click)="toggle.emit('LIST')"
			[ngClass]="
				mode === 'LIST'
					? 'bg-white rounded-full	text-[#2ccbba] dark:bg-neutral-800'
					: ''
			"
			class="inline-flex items-center transition-colors duration-300 ease-in focus:outline-none rounded-r-full px-4 py-2"
			id="list">
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				stroke="currentColor"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
				class="fill-current w-4 h-4 mr-2">
				<line x1="8" y1="6" x2="21" y2="6"></line>
				<line x1="8" y1="12" x2="21" y2="12"></line>
				<line x1="8" y1="18" x2="21" y2="18"></line>
				<line x1="3" y1="6" x2="3.01" y2="6"></line>
				<line x1="3" y1="12" x2="3.01" y2="12"></line>
				<line x1="3" y1="18" x2="3.01" y2="18"></line>
			</svg>
			<span>List</span>
		</button>
	</div>`,
})
export class SwitchModeComponent {
	@Input() mode: DisplayMode;
	@Output() toggle = new EventEmitter();

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.mode && this.mode) {
			this.mode = changes.mode.currentValue;
		}
	}
}
