import {Pipe, PipeTransform} from '@angular/core';

// type TimeUnit = 'YEAR' |'MONTH' |'WEEK' |'DAY' |'HOUR' |'MINUTE' |'SECOND';

@Pipe({
	standalone: true,
	name: 'duration',
	pure: false,
})
export class DurationPipe implements PipeTransform {
	transform(seconds?: number | null): string {
		if (!seconds) {
			return '--';
		}
		if (seconds < 1) {
			return '';
		}
		if (seconds < 60) {
			return `${seconds} sec`;
		}
		const secondsRemaining = seconds % 60;
		const minutesValue = Math.floor(seconds / 60);
		if (seconds < 3600 && secondsRemaining === 0) {
			return `${minutesValue} min`;
		}
		if (seconds < 3600) {
			return `${minutesValue} min ${secondsRemaining} sec`;
		}
		const minutesRemaining = minutesValue % 60;
		const hoursValue = Math.floor(seconds / 3600);
		const minutesDisplay =
			minutesRemaining !== 0 ? `${minutesRemaining} min` : '';
		if (seconds > 3600) {
			return `${hoursValue} h ${minutesDisplay}`;
		}
		const secondsDisplay =
			secondsRemaining !== 0 ? `${secondsRemaining} sec` : '';
		return `${hoursValue} h ${minutesDisplay} ${secondsDisplay}`;
	}

	/*
  private handleTimeConversion(seconds: number, start: TimeUnit, end: TimeUnit): string {
    let endTime = 0;
    let finalTime = '';
    switch (end) {
      case 'MINUTE':
        endTime = seconds / 60;
        finalTime = this.convertTime(endTime, start, end);
        break;
      case 'HOUR':
        endTime = seconds / 3600;
        finalTime = this.convertTime(endTime, start, end);
        break;
      case 'DAY':
        endTime = seconds / 86400;
        finalTime = this.convertTime(endTime, start, end);
        break;
      case 'WEEK':
        endTime = seconds / 604800;
        finalTime = this.convertTime(endTime, start, end);
        break;
      case 'MONTH':
        endTime = seconds / 18446400;
        finalTime = this.convertTime(endTime, start, end);
        break;
      case 'YEAR':
        endTime = seconds / 6732936000;
        finalTime = this.convertTime(endTime, start, end);
        break;
    }
    return finalTime;
  }
  */
}
