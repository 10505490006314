<div
	class="relative flex flex-wrap items-center justify-center"
	*ngIf="objectValue">
	<mat-form-field
		(click)="$event.stopPropagation()"
		class="max-w-xl flex-1 autoComplete no-subscript">
		<button
			*ngIf="objectValue.current && objectValue.current.length > 0"
			matSuffix
			mat-icon-button
			aria-label="Clear current selection"
			(click)="clearAll()">
			<mat-icon>close</mat-icon>
		</button>
		<mat-chip-grid #chipList aria-label="autocomplete selection">
			<div class="overflow-y-auto max-w-full mx-auto max-h-20">
				<mat-chip-row
					*ngFor="let value of objectValue.current"
					(removed)="remove(value)">
					<green-truncated-text
						[value]="value"
						[maxLength]="7"></green-truncated-text>
					<button matChipRemove class="is-warn">
						<mat-icon inline>close</mat-icon>
					</button>
				</mat-chip-row>
			</div>
			<input
				[placeholder]="
					'SHARED.TAGS.' + type.toLocaleUpperCase() + '_PLACEHOLDER' | translate
				"
				#valueInput
				[value]="valueCtrl"
				[ngModel]="valueCtrl"
				(ngModelChange)="valueCtrlOnChange($event)"
				[matAutocomplete]="auto"
				[matChipInputFor]="chipList"
				(keydown.enter)="handleKeydownEnter()"
				[matChipInputSeparatorKeyCodes]="separatorKeysCodes" />
		</mat-chip-grid>
		<mat-autocomplete
			#auto="matAutocomplete"
			(optionSelected)="selected($event)">
			<mat-option *ngFor="let value of filteredValues" [value]="value">
				{{ value }}
			</mat-option>
		</mat-autocomplete>
	</mat-form-field>
	<div style="position: absolute; right: 0; bottom: -45px">
		<button
			color="primary"
			mat-mini-fab
			class="mr-1"
			aria-label="reset selection"
			(click)="resetObjectValue()">
			<mat-icon>replay</mat-icon>
		</button>
		<button
			color="accent"
			mat-mini-fab
			aria-label="confirm selection"
			(click)="emitObjectValue()">
			<mat-icon>check</mat-icon>
		</button>
	</div>
</div>
